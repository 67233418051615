<template>
  <div>
    <h1 class=" text-text2 text-center text-4xl mt-6">No reports available at this time.</h1>
  </div>
</template>
<script>
export default {
  components: {
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'Dealer Network ◾ Lead Flow'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>